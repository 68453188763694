//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
export default {
  props: {
    initUrl: {
      type: String,
      default: null
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    isSubmited: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('user', ['token']),
    isEmptyFileList() {
      return this.fileList.length === 0;
    },
    validateStatus() {
      return this.errorMessageFile || (this.isEmptyFileList && this.isSubmited) ? 'error' : '';
    },
    errorMessage() {
      if (this.isEmptyFileList && this.isSubmited) {
        return this.$t('message.compose.ImageIsRequired');
      }
      if (this.errorMessageFile) {
        return this.$t(this.errorMessageFile);
      }
      return '';
    }
  },
  data() {
    return {
      action: `${process.env.VUE_APP_API_BASE_URL}/hub/upload`,
      loading: false,
      imageUrl: this.initUrl,

      fileList: [],
      errorMessageFile: ''
    };
  },
  created() {
    if (this.initUrl) {
      const fileName = this.initUrl.split('/').pop();
      this.fileList = [
        {
          uid: '-1',
          name: decodeURIComponent(fileName),
          status: 'done',
          url: this.initUrl
        }
      ];
    }
  },
  methods: {
    handleChange(info) {
      let fileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      //    Only to show one recent uploaded files
      fileList = fileList.slice(-1);

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          const { status } = file.response;
          if (status === 'OK' && file.response.data && file.response.data.length) {
            file.url = file.response.data[0].link;
          }
          if (status === 'NG') {
            file.status = 'error';
          }
        }
        return file;
      });

      this.fileList = fileList;

      if (info.file.status === 'done') {
        this.errorMessageFile = '';
        this.$emit('change', this.fileList.length && this.fileList[0].url);
      } else if (info.file.status === 'removed') {
        this.errorMessageFile = '';
        this.$emit('remove');
      }
    },
    beforeUpload(file) {
      const fileExtension = file.name.split('.').pop();
      const whiteList = [
        'jpeg',
        'jpg',
        'jfif',
        'pjpeg',
        'pjp',
        'png',
        'gif',
        'svg',
        'bmp',
        'tif',
        'tiff',
        'webp'
      ];
      if (!whiteList.includes(fileExtension.toLowerCase())) {
        this.errorMessageFile = 'File must be Image Format!';
        // this.$message.error(this.$t("File must be Image Format!"))
        return Promise.reject(false);
      }
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.errorMessageFile = 'Image must smaller than 10MB!';
        // this.$message.error(this.$t("Image must smaller than 10MB!"));
        return Promise.reject(false);
      }
      return true;
    }
  }
};
