//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { modifyTextForLinks } from '@/utils/util';
export default {
  props: {
    message: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      isChanged: false,
      form: this.$form.createForm(this, {
        onValuesChange: () => {
          this.isChanged = true;
        }
      })
    };
  },
  computed: {
    lang() {
      return this.message.lang.value;
    },
    formItemLayout() {
      return {
        labelCol: { lg: { span: 4 }, md: { span: 4 }, sm: { span: 24 } },
        wrapperCol: { lg: { span: 20 }, md: { span: 20 }, sm: { span: 24 } }
      };
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
    },
    hypeLinkedAuto(value, className) {
      return modifyTextForLinks(value, className);
    }
  }
};
