var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false, bodyStyle: { padding: "0px" } } },
    [
      _c(
        "a-form-model",
        {
          ref: "schedulingForm",
          attrs: {
            rules: _vm.rules,
            model: _vm.scheduling,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol
          }
        },
        [
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("Starting time"),
                prop: "starting_time",
                help:
                  _vm.canSave &&
                  _vm.$t("format.scheduling", {
                    minSending: _vm.currentDate.format("YYYY-MM-DD HH:mm")
                  })
              }
            },
            [
              _c(
                "a-date-picker",
                {
                  staticStyle: { width: "220px" },
                  attrs: {
                    "show-time": { format: "HH:mm" },
                    placeholder: _vm.$t("Select Time"),
                    "show-today": false,
                    format: "YYYY-MM-DD HH:mm",
                    allowClear: false,
                    inputReadOnly: true,
                    autoFocus: false,
                    "disabled-date": _vm.disabledStartingDate,
                    "disabled-time": _vm.disabledDateTime,
                    disabled: !_vm.canSave
                  },
                  model: {
                    value: _vm.scheduling.starting_time,
                    callback: function($$v) {
                      _vm.$set(_vm.scheduling, "starting_time", $$v)
                    },
                    expression: "scheduling.starting_time"
                  }
                },
                [
                  !_vm.canSave
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("dayjs")(
                              _vm.pushMessage.starting_time,
                              "YYYY-MM-DD HH:mm"
                            )
                          )
                        )
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-bottom": "0" },
              attrs: { label: _vm.$t("Repeated Times") }
            },
            [
              _c(
                "a-form-model-item",
                {
                  style: {
                    display: "inline-block",
                    width: "220px",
                    margin: "0 0 4px 0"
                  },
                  attrs: { prop: "frequency_type" }
                },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "220px" },
                      attrs: {
                        disabled: !_vm.canSave,
                        value: _vm.frequencyType || "ONCE"
                      }
                    },
                    _vm._l(_vm.repeatTimes, function(item) {
                      return _c(
                        "a-select-option",
                        {
                          key: item.value,
                          attrs: { value: item.value },
                          on: { click: _vm.handleSelect }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.label === "format.repeat"
                                  ? _vm.$tc(item.label, 0)
                                  : _vm.$t(item.label)
                              ) +
                              " "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c("span", { attrs: { slot: "help" }, slot: "help" }, [
                _vm._v(" " + _vm._s(_vm.combindHelpText) + " ")
              ])
            ],
            1
          ),
          _vm.showRepeat
            ? _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("message.compose.End time"),
                    prop: "end_time"
                  }
                },
                [
                  _c(
                    "a-date-picker",
                    {
                      staticStyle: { width: "220px" },
                      attrs: {
                        placeholder: _vm.$t("Select Time"),
                        "show-today": false,
                        format: "YYYY-MM-DD",
                        allowClear: false,
                        inputReadOnly: true,
                        autoFocus: false,
                        "disabled-date": _vm.disabledEndDate,
                        "disabled-time": _vm.disabledDateTime,
                        disabled: !_vm.canSave
                      },
                      model: {
                        value: _vm.scheduling.end_time,
                        callback: function($$v) {
                          _vm.$set(_vm.scheduling, "end_time", $$v)
                        },
                        expression: "scheduling.end_time"
                      }
                    },
                    [
                      !_vm.canSave
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("dayjs")(
                                  _vm.scheduling.end_time,
                                  "YYYY-MM-DD"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.showAdvance
        ? _c("advance-setting", {
            attrs: {
              visible: _vm.showAdvance,
              isCustomFrequency: _vm.isCustomFrequency,
              frequency_type: _vm.scheduling.frequency_type,
              initialValue: _vm.scheduling
            },
            on: { close: _vm.onCloseAdvance, done: _vm.onAdvanceSetting }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }