const mixin = {
  props: {
    initialValue: null,
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {}
};

export default mixin;
