var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-item",
        {
          staticClass: "py-none my-none",
          attrs: {
            "validate-status": _vm.validateStatus,
            help: _vm.errorMessage
          }
        },
        [
          _c(
            "a-input",
            {
              attrs: { value: _vm.url, disabled: !_vm.canEdit },
              on: { change: _vm.handleChange }
            },
            [
              _c(
                "a-tooltip",
                {
                  attrs: {
                    slot: "prefix",
                    title: _vm.$t("Open link"),
                    disabled: !_vm.isURL
                  },
                  slot: "prefix"
                },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.url,
                        target: "_blank",
                        disabled: !_vm.isURL
                      }
                    },
                    [_c("a-icon", { attrs: { type: "link" } })],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }