//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LocationSelect from './LocationSelect';
// import AgeSelect from "./AgeSelect";
// import GenderSelect from "./GenderSelect";
import AppTarget from './AppTarget';

export default {
  components: {
    LocationSelect,
    // AgeSelect,
    // GenderSelect,
    AppTarget
  },
  props: {
    currentSize: {
      type: Number,
      default: 0
    },
    readonly: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    initialTarget: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    },
    targetOptions: {
      type: Array,
      required: true
    }
  },
  watch: {
    'target.value'() {
      this.$emit('changed', this.target);
    },
    'target.key'() {
      this.target.value = null;
    }
  },
  computed: {
    canRemove() {
      if (this.readonly) {
        return false;
      }
      return true;
    },
    canAdd() {
      if (this.readonly) {
        return false;
      }

      if (!this.target.value) {
        return false;
      }

      // only last item can enable add button
      if (this.index < this.currentSize - 1) {
        return false;
      }

      // can be added max total target options.
      if (this.currentSize === this.targetOptions.length) {
        return false;
      }
      return true;
    },

    // showGenderSelect() {
    //   return this.target && this.target.key === "gender";
    // },
    // showAgeSelect() {
    //   return this.target && this.target.key === "age";
    // },
    showLocationSelect() {
      return this.target && this.target.key === 'location';
    },
    showAppTarget() {
      return this.target && this.target.key === 'appTarget';
    }
  },
  data() {
    return {
      target: {}
    };
  },
  created() {
    this.target = { ...this.initialTarget };
  },
  methods: {
    onChangedTarget(newValue) {
      this.target = {
        ...this.target,
        value: newValue
      };
    },

    remove() {
      this.$emit('remove', this.$vnode.key);
    },

    add() {
      this.$emit('add');
    }
  }
};
