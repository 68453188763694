//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import { mapState } from 'vuex';
import { messageMixin } from './mixin';
import { standardizedParmas } from '@/utils/util';
import { timer } from 'rxjs';
export default {
  components: {
    AdvanceSetting: () => import('./schedule/Advance')
  },
  mixins: [messageMixin],
  data() {
    return {
      labelCol: { lg: { span: 4 }, md: { span: 4 }, sm: { span: 24 } },
      wrapperCol: { lg: { span: 20 }, md: { span: 20 }, sm: { span: 24 } },
      scheduling: {},
      showAdvance: false,
      currentDate: moment(),
      frequencyType: 'ONCE',
      isCustomFrequency: false,
      repeatTimes: [
        {
          value: 'ONCE',
          label: 'format.repeat'
        },
        {
          value: 'DAILY',
          label: 'message.compose.Daily'
        },
        {
          value: 'WEEKLY',
          label: 'message.compose.Weekly'
        },
        {
          value: 'MONTHLY',
          label: 'message.compose.Monthly'
        },
        {
          value: 'CUSTOM',
          label: 'message.compose.Custom'
        }
      ]
    };
  },

  computed: {
    ...mapState('user', ['setting']),
    rules() {
      return this.canSave
        ? {
          starting_time: [
            {
              validator: (rule, value, callback) => {
                if (value && moment(value) < this.currentDate) {
                  callback(
                    new Error(
                      this.$t('format.scheduling', {
                        minSending: this.currentDate.format('YYYY-MM-DD HH:mm')
                      })
                    )
                  );
                } else {
                  callback();
                }
              },
              trigger: ['change', 'blur']
            }
          ],
          end_time: [
            {
              required: true,
              message: this.$t('message.compose.Please pick a date')
            }
          ]
        }
        : {};
    },
    showRepeat() {
      return this.scheduling.frequency_type !== 'ONCE';
    },

    combindHelpText() {
      const data = moment(this.scheduling.starting_time);
      const { advance, frequency_type } = this.scheduling;
      if (!advance) return '';
      return this.buildTextRepeat(this.scheduling.frequency_type, {
        months: advance?.months?.map(item => this.$t(item)).join(',') ?? '',
        days:
          advance?.days
            ?.map(item => {
              return item === 'Last' ? this.$t('message.compose.Last') : this.$t(item);
            })
            .join(frequency_type === 'WEEKLY' ? '/' : ',') ?? '',
        time: data.format('HH:mm'),
        repeat: advance.repeat_every
      });
    }
  },
  watch: {
    'scheduling.starting_time'() {
      this.emit();
      const endTime = moment(this.scheduling.end_time);
      const startingTime = moment(this.scheduling.starting_time);
      if (endTime <= startingTime) {
        this.scheduling.end_time = startingTime.add(1, 'day');
      }
    }
  },
  created() {
    this.initial();
  },
  methods: {
    moment,
    initial() {
      const min = this.setting.auto_sending + 1;
      this.$subscribeTo(timer(1000, 1000), () => {
        this.currentDate = moment().add(min, 'minutes');
        if (this.scheduling && this.scheduling.starting_time) {
          if (this.scheduling.starting_time < this.currentDate) {
            this.scheduling.starting_time = this.currentDate;
          }
        }
      });
      const {
        starting_time,
        end_time,
        sending_schedule,
        frequency_type,
        is_custom_frequency
      } = this.pushMessage;
      this.isCustomFrequency = is_custom_frequency;
      this.frequencyType = is_custom_frequency ? 'CUSTOM' : frequency_type;
      this.scheduling = starting_time
        ? {
          starting_time:
            (starting_time && moment(starting_time)) ||
            this.minSending(this.setting.auto_sending),
          end_time: (end_time && moment(end_time)) || null,
          frequency_type: frequency_type ?? 'ONCE',
          advance: {
            ...(sending_schedule && sending_schedule)
          }
        }
        : {
          starting_time: this.minSending(this.setting.auto_sending + 1),
          end_time: null,
          frequency_type: 'ONCE',
          advance: {
            repeat_every: 0
          }
        };
    },
    async emit() {
      const value = await this.isValid();
      return new Promise(resolve => {
        this.$emit('isValid', value);
        this.$nextTick(resolve);
      });
    },
    minSending(buffer = 0) {
      // const min = this.setting.auto_sending || 0;
      // return moment().add(min + buffer, "minutes");
      return moment().add(buffer, 'minutes');
    },
    onChange(value, dateString) {
      console.log('Selected Time: ', value);
      console.log('Formatted Selected Time: ', dateString);
    },
    onOk(value) {
      console.log('onOk: ', value);
    },

    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },

    disabledStartingDate(current) {
      // Only allow from today at 00:00 and future
      const today = moment().startOf('day');
      return current && current < today;
    },

    disabledEndDate(current) {
      const startingTime = moment(this.scheduling.starting_time);
      return current && current <= startingTime;
    },

    disabledDateTime() {
      return {
        disabledHours: () => [],
        disabledMinutes: () => []
      };
    },

    async isValid() {
      try {
        await this.validate();
        return true;
      } catch (error) {
        return false;
      }
    },

    openAdvance() {
      this.showAdvance = true;
    },

    onCloseAdvance(value) {
      this.scheduling = { ...value };
      this.frequencyType = this.isCustomFrequency ? 'CUSTOM' : value.frequency_type;
      this.showAdvance = false;
    },

    onAdvanceSetting(data, frequencyType) {
      const advance = {
        DAILY: {
          repeat_every: data.repeat_every
        },
        WEEKLY: {
          repeat_every: data.repeat_every,
          days: data.daysOfWeek
        },
        MONTHLY: {
          months: data.months,
          days: data.daysOfMonth
        }
      };
      this.isCustomFrequency = true;
      this.scheduling = {
        ...this.scheduling,
        advance: advance[frequencyType],
        frequency_type: frequencyType
      };
      this.showAdvance = false;
    },

    handleSelect(e) {
      const forceMomentEn = moment(moment().format('DD-MM-YYYY'), 'DD-MM-YYYY', 'en');
      const initMonth = [...Array(12).keys()].map(i => forceMomentEn.month(i).format('MMMM'));
      const mapping = {
        ONCE: {
          repeat_every: 0
        },
        DAILY: {
          repeat_every: 1
        },
        WEEKLY: {
          repeat_every: 1,
          days: [
            moment(moment(this.scheduling.starting_time).format('DD-MM-YYYY'), 'DD-MM-YYYY', 'en')
              .isoWeekday(moment(this.scheduling.starting_time).isoWeekday())
              .format('dddd')
          ]
        },
        MONTHLY: {
          months: [...initMonth],
          days: [
            moment(
              moment(this.scheduling.starting_time).format('DD-MM-YYYY'),
              'DD-MM-YYYY',
              'en'
            ).format('D')
          ]
        }
      };
      this.frequencyType = e.key;
      if (e.key === 'CUSTOM') {
        this.openAdvance();
      } else {
        this.isCustomFrequency = false;
        this.scheduling = {
          ...this.scheduling,
          frequency_type: e.key,
          advance: mapping[e.key]
        };
      }
    },

    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.schedulingForm.validate(valid => {
          if (valid) {
            const startingTime = moment(this.scheduling.starting_time);
            const hour = startingTime.hour();
            const minute = startingTime.minute() + this.setting.auto_sending + 1;
            resolve(
              standardizedParmas({
                ...this.scheduling,
                starting_time: moment(this.scheduling.starting_time).format(),
                is_custom_frequency: this.isCustomFrequency,
                ...(this.scheduling.advance && {
                  sending_schedule: this.scheduling.advance
                }),
                ...(this.scheduling.frequency_type !== 'ONCE' && {
                  end_time: moment(this.scheduling.end_time)
                    .set({ hour: hour, minute: minute })
                    .format()
                })
              })
            );
          } else {
            reject({ type: 'scheduling' });
          }
        });
      });
    },

    buildTextRepeat(type, { months, days, time, repeat }) {
      const mapping = {
        ONCE: {
          0: '',
          multi: ''
        },
        DAILY: {
          1: this.$t('message.compose.Daily message single', { time }),
          multi: this.$t('message.compose.Daily message multi', { repeat, time })
        },
        WEEKLY: {
          1: this.$t('message.compose.Weekly message single', { days, time }),
          multi: this.$t('message.compose.Weekly message multi', { repeat, days, time })
        },
        MONTHLY: {
          12: this.$t('message.compose.Monthly message single', { days, time }),
          multi: this.$t('message.compose.Monthly message multi', { months, days, time })
        }
      };
      return type === 'MONTHLY'
        ? mapping[type][months.split(',').length] || mapping[type].multi
        : mapping[type][repeat] || mapping[type].multi;
    }
  }
};
