var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-upload",
        {
          staticClass: "avatar-uploader",
          attrs: {
            "list-type": "picture-card",
            accept: "image/*",
            name: "files",
            action: _vm.action,
            headers: {
              Authorization: _vm.token
            },
            "before-upload": _vm.beforeUpload,
            "file-list": _vm.fileList,
            disabled: !_vm.canEdit,
            remove: _vm.handleRemove
          },
          on: { change: _vm.handleChange, preview: _vm.handlePreview }
        },
        [
          _vm.canEdit && _vm.fileList.length < 1
            ? _c(
                "div",
                [
                  _c("a-icon", {
                    attrs: { type: _vm.loading ? "loading" : "plus" }
                  }),
                  _c("div", { staticClass: "ant-upload-text" }, [
                    _vm._v(" " + _vm._s(_vm.$t("Upload")) + " ")
                  ])
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _vm.isValidFile
        ? _c("a-alert", {
            staticStyle: { "margin-top": "1rem" },
            attrs: {
              message: _vm.$t(
                "There is a problem with the uploaded file. This file will not be uploaded"
              ),
              type: "error",
              "show-icon": ""
            }
          })
        : _vm._e(),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.previewVisible, footer: null },
          on: { cancel: _vm.handleCancel }
        },
        [
          _c(
            "div",
            { staticClass: "review-wrap" },
            [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: _vm.previewImage,
                    expression: "previewImage"
                  }
                ],
                staticClass: "review-wrap-img",
                attrs: { alt: "example" }
              }),
              _c(
                "a-button",
                {
                  staticClass: "mt-xs review-wrapp-button",
                  attrs: {
                    disabled: !_vm.previewImage,
                    type: "link",
                    icon: "download"
                  },
                  on: { click: _vm.downloadLink }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Download")) + " ")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }