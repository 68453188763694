//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { TreeSelect } from 'ant-design-vue/lib';
import mixin from './mixin';
export default {
  mixins: [mixin],
  data() {
    return {
      SHOW_PARENT: TreeSelect.SHOW_PARENT,
      model: []
    };
  },
  computed: {
    ...mapGetters('resource', {
      treeData: 'treeDataStates'
    })
  },
  watch: {
    model(newValues) {
      const filter_prefecture = this.splitData(newValues, 'pref');
      const filter_ward = this.splitData(newValues, 'ward');
      const filter_city = this.splitData(newValues, 'city');

      this.$emit('changed', {
        filter_prefecture,
        filter_ward,
        filter_city
      });
    }
  },
  async created() {
    this.model = this.initialValue || [];
  },
  methods: {
    splitData(data, prefix = 'pref') {
      return data
        .filter(value => value.startsWith(prefix))
        .map(item => {
          const index = item.lastIndexOf('-');
          const key = item.substr(index + 1);
          return key;
        });
    }
  }
};
