var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false, bodyStyle: { padding: "0px" } } },
    [
      _c(
        "a-form",
        {
          staticClass: "form",
          attrs: { form: _vm.form },
          on: { submit: _vm.handleSubmit }
        },
        [
          _c(
            "a-form-item",
            { staticStyle: { margin: "0" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "lang",
                      {
                        initialValue: _vm.lang
                      }
                    ],
                    expression:
                      "[\n          'lang',\n          {\n            initialValue: lang\n          }\n        ]"
                  }
                ],
                attrs: { hidden: "" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: _vm.$t("Message title"),
                "label-col": _vm.formItemLayout.labelCol,
                "wrapper-col": _vm.formItemLayout.wrapperCol
              }
            },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "title",
                      {
                        rules: [
                          {
                            required: true,
                            message: _vm.$t("Title is required")
                          },
                          {
                            max: 65,
                            message: _vm.$t("Title max 65 length")
                          }
                        ],
                        initialValue: _vm.message.title
                      }
                    ],
                    expression:
                      "[\n          'title',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('Title is required')\n              },\n              {\n                max: 65,\n                message: $t('Title max 65 length')\n              }\n            ],\n            initialValue: message.title\n          }\n        ]"
                  }
                ],
                attrs: {
                  disabled: !_vm.canEdit,
                  placeholder: _vm.$t("TitleMaxLength", { length: 65 })
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: _vm.$t("Content"),
                "label-col": _vm.formItemLayout.labelCol,
                "wrapper-col": _vm.formItemLayout.wrapperCol
              }
            },
            [
              _c("a-textarea", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.canEdit,
                    expression: "canEdit"
                  },
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "content",
                      {
                        rules: [
                          {
                            required: true,
                            message: _vm.$t("Content is required")
                          },
                          {
                            max: 240,
                            message: _vm.$t("Title max 240 length")
                          }
                        ],
                        initialValue: _vm.message.content
                      }
                    ],
                    expression:
                      "[\n          'content',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('Content is required')\n              },\n              {\n                max: 240,\n                message: $t('Title max 240 length')\n              }\n            ],\n            initialValue: message.content\n          }\n        ]"
                  }
                ],
                attrs: {
                  rows: 4,
                  placeholder: _vm.$t("ContentMaxLength", { length: 240 })
                }
              }),
              !_vm.canEdit
                ? _c("div", {
                    staticClass: "content-container",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$options.filters.nl2br(
                          _vm.hypeLinkedAuto(_vm.message.content, "")
                        )
                      )
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }