//
//
//
//
//
//
//
//
//
//
//
//
//

import { validateURL } from '@/utils/util';
export default {
  props: {
    initUrl: {
      type: String,
      default: null
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    isSubmited: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      url: ''
    };
  },
  computed: {
    isURL() {
      return validateURL(this.url);
    },
    validateStatus() {
      return (!this.url || !this.isURL) && this.isSubmited ? 'error' : '';
    },
    errorMessage() {
      if (!this.url && this.isSubmited) {
        return this.$t('message.compose.URLIsRequired');
      }
      if (!this.isURL && this.isSubmited) {
        return this.$t('message.compose.URLIsInvalid');
      }
      return '';
    }
  },
  created() {
    this.url = this.initUrl;
  },
  methods: {
    handleChange({ target }) {
      this.url = target.value;
      this.$emit('change', target.value);
    }
  }
};
