var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-tabs",
        {
          attrs: { "hide-add": "", type: "editable-card", id: "root-tab" },
          on: { edit: _vm.onEdit },
          model: {
            value: _vm.activeKey,
            callback: function($$v) {
              _vm.activeKey = $$v
            },
            expression: "activeKey"
          }
        },
        [
          _vm.canSave || _vm.canSubmit
            ? _c("a-button", {
                attrs: { slot: "tabBarExtraContent", icon: "plus" },
                on: { click: _vm.showSelectLang },
                slot: "tabBarExtraContent"
              })
            : _vm._e(),
          _vm._l(_vm.localizes, function(localize) {
            return _c(
              "a-tab-pane",
              {
                key: localize.lang.value,
                attrs: { closable: _vm.closable(localize), forceRender: "" }
              },
              [
                _c(
                  "template",
                  { slot: "tab" },
                  [
                    _vm._v(_vm._s(_vm.$t(localize.lang.label)) + " "),
                    _vm.isError(localize.lang.value)
                      ? _c("a-icon", {
                          attrs: {
                            type: "warning",
                            theme: "twoTone",
                            "two-tone-color": "#eb2f96"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c("BaseForm", {
                  key: localize.lang.value,
                  ref: "base",
                  refInFor: true,
                  attrs: { "can-edit": _vm.canSave, message: localize }
                })
              ],
              2
            )
          })
        ],
        2
      ),
      _c(
        "a-form",
        { attrs: { form: _vm.form }, on: { submit: _vm.handleSubmit } },
        [
          _c(
            "a-form-item",
            {
              attrs: {
                label: _vm.$t("Attachment"),
                "label-col": _vm.formItemLayout.labelCol,
                "wrapper-col": _vm.formItemLayout.wrapperCol
              }
            },
            [
              _c(
                "q-list",
                { staticClass: "rounded-borders", attrs: { bordered: "" } },
                [
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        { attrs: { avatar: "", top: "" } },
                        [
                          _c("q-icon", {
                            attrs: {
                              name: "create_new_folder",
                              color: "black",
                              size: "34px"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        { staticClass: "col-8", attrs: { center: "" } },
                        [
                          _c("q-item-label", { staticClass: "q-mt-sm" }, [
                            _vm._v(" " + _vm._s(_vm.$t("Attachment")) + " ")
                          ])
                        ],
                        1
                      ),
                      _c("q-space"),
                      _c(
                        "q-item-section",
                        { attrs: { center: "", side: "" } },
                        [
                          _c(
                            "div",
                            { staticClass: "text-grey-8 q-gutter-xs" },
                            [
                              _vm.attachments.length === 0
                                ? _c("q-btn", {
                                    attrs: {
                                      flat: "",
                                      color: "primary",
                                      label: _vm.$t("Add attachment"),
                                      disabled: !_vm.canSave
                                    },
                                    on: { click: _vm.addAttachment }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.attachments, function(attachment, index) {
                    return _c("attachment-option", {
                      key: attachment.id,
                      attrs: {
                        attachmentOptions: _vm.attachmentOptions,
                        initialAttachment: attachment,
                        "current-size": _vm.attachments.length,
                        index: index + 1,
                        "can-edit": _vm.canSave,
                        "is-submited": _vm.isSubmited
                      },
                      on: {
                        changeAttachment: _vm.changeAttachment,
                        add: _vm.addAttachment,
                        remove: _vm.removeAttachment
                      }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              staticClass: "mb-none",
              attrs: {
                label: _vm.$t("Voice code"),
                "label-col": _vm.formItemLayout.labelCol,
                "wrapper-col": _vm.formItemLayout.wrapperCol
              }
            },
            [
              _c("QRUploader", {
                attrs: {
                  "can-edit": _vm.canSave,
                  initUrl: _vm.qrcode && _vm.qrcode.link
                },
                on: { change: _vm.onQrUrlChange, remove: _vm.onQrRemove }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              staticClass: "mb-none",
              attrs: { "wrapper-col": _vm.formItemTail.wrapperCol }
            },
            [
              _vm.codeId && _vm.pageId
                ? _c("a", { on: { click: _vm.redirectToCodeList } }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("Link to Voice Code")))])
                  ])
                : _vm._e()
            ]
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.$t("Add languages"),
            "on-ok": "handleOk",
            destroyOnClose: true
          },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                {
                  key: "submit",
                  attrs: { type: "primary", loading: _vm.confirmLoading },
                  on: { click: _vm.handleOk }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Select")) + " ")]
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c("a-checkbox-group", {
                attrs: {
                  options: _vm.localizeSupported,
                  "default-value": _vm.selectedLangs
                },
                on: { change: _vm.onChange }
              })
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }