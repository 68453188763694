var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.loading } },
    [
      _c("page-header", {
        staticClass: "mb-xs",
        attrs: {
          title: _vm.isEdit
            ? _vm.$t("Message detail")
            : _vm.$t("Compose message"),
          "show-back-icon": ""
        },
        on: {
          back: function($event) {
            return _vm.$router.go(-1)
          }
        }
      }),
      _c(
        "div",
        [
          _c(
            "a-card",
            {
              staticClass: "compose--card",
              attrs: { title: _vm.$t("Message content"), loading: _vm.fetching }
            },
            [
              _c("Content", {
                ref: "refContent",
                attrs: { "push-message": _vm.message, "is-edit": _vm.isEdit }
              })
            ],
            1
          ),
          _c(
            "a-card",
            { staticClass: "compose--card", attrs: { loading: _vm.fetching } },
            [
              _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                _c("p", { staticStyle: { "margin-bottom": "0" } }, [
                  _vm._v(_vm._s(_vm.$t("Targeting")))
                ])
              ]),
              _c("Targeting", {
                ref: "refTargeting",
                attrs: { "push-message": _vm.message, "is-edit": _vm.isEdit }
              }),
              _vm.done.targeting
                ? _c(
                    "div",
                    { staticClass: "q-mt-md error" },
                    [
                      _c(
                        "a-alert",
                        { attrs: { type: "error", "show-icon": "" } },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "message" }, slot: "message" },
                            _vm._l(_vm.errors, function(err, i) {
                              return _c("div", { key: i }, [
                                err.key === "location"
                                  ? _c("p", { staticClass: "q-mb-none" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("Location exceed maximum")
                                          ) +
                                          " "
                                      )
                                    ])
                                  : _c("p", { staticClass: "q-mb-none" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "A valid targeting condition is required"
                                          )
                                        )
                                      )
                                    ])
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "a-card",
            {
              staticClass: "compose--card",
              attrs: { title: _vm.$t("Scheduling"), loading: _vm.fetching }
            },
            [
              _c("Scheduling", {
                ref: "refScheduling",
                attrs: { "push-message": _vm.message, "is-edit": _vm.isEdit }
              })
            ],
            1
          ),
          _c(
            "a-card",
            { attrs: { loading: _vm.fetching } },
            [
              _c(
                "a-row",
                { attrs: { type: "flex", align: "middle", justify: "center" } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { width: "95%" },
                          attrs: { icon: "eye", type: "primary", ghost: "" },
                          on: { click: _vm.showPreview }
                        },
                        [_vm._v(_vm._s(_vm.$t("Preview")) + " ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { width: "95%" },
                          attrs: {
                            disabled: !_vm.canSave,
                            type: "dashed",
                            icon: "copy"
                          },
                          on: { click: _vm.saveDraf }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Save as draft")) + " ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-popconfirm",
                        {
                          staticStyle: { width: "95%" },
                          attrs: {
                            disabled: !_vm.canSave,
                            placement: "top",
                            title: _vm.$t(
                              "Are you sure to publish this message now ?"
                            ),
                            "ok-text": _vm.$t("Yes"),
                            "cancel-text": _vm.$t("No")
                          },
                          on: {
                            confirm: function() {
                              return _vm.submit("save", false)
                            }
                          }
                        },
                        [
                          _c(
                            "a-button",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled: !_vm.canSave,
                                type: "primary",
                                icon: "snippets"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Compose Submit")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("preview", { ref: "previewRef" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }