//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TargetOption from './selects/TargetOption';

import { BASE_TARGET_OPTIONS } from '@/enum';
import { mapGetters } from 'vuex';
import { messageMixin } from './mixin';

export default {
  components: {
    TargetOption
  },
  mixins: [messageMixin],
  props: {
    disabled: { type: Boolean, default: null }
  },
  data() {
    return {
      targets: [],
      index: 1000
    };
  },
  computed: {
    ...mapGetters('resource', ['cityCodeToName', 'prefData']),
    formItemLayout() {
      return {
        labelCol: { lg: { span: 0 }, md: { span: 0 }, sm: { span: 24 } },
        wrapperCol: { lg: { span: 24 }, md: { span: 24 }, sm: { span: 24 } }
      };
    },
    // targetMsg() {
    //   const targets = this.targets.map(target => {
    //     let s = "";
    //     if (target.key === "gender") {
    //       s = `${this.$t("Gender")}: ${this.$t(target.value)}`;
    //     } else if (target.key === "age" && target.value) {
    //       s = `${this.$t("Age")}: ${target.value.min} ~ ${target.value.max - 1}`;
    //     } else if (target.key === "location" && target.value) {
    //       s = `${this.$t("Location")}: ${this.getCityName(target.value)}`;
    //     }

    //     return s;
    //   });

    //   return targets.length ? targets.join(", ") : this.$t("All users");
    // },

    targetSettings() {
      const { app_target, filter_city, filter_prefecture, filter_ward } = this.pushMessage;

      const prefs = filter_prefecture?.map(item => `pref-${item}`) || [];
      const wards = filter_ward?.map(item => `ward-${item}`) || [];
      const cities = filter_city?.map(item => `city-${item}`) || [];
      return {
        // ...(filter_gender && { gender: filter_gender }),
        // ...(filter_max_age && { age: { max: filter_max_age, min: filter_min_age } }),
        ...((filter_prefecture?.length || filter_ward?.length || filter_city?.length) && {
          location: [...prefs, ...wards, ...cities]
        }),
        ...(app_target && { appTarget: app_target })
      };
    },
    isDisabled() {
      // canSave and disabled are props
      if (this.disabled !== null) return this.disabled;
      return !this.canSave;
    }
  },
  watch: {
    targetSettings: {
      handler() {
        this.targets = Object.keys(this.targetSettings).map(key => {
          return { key, operator: 'is', value: this.targetSettings[key], id: this.index++ };
        });
      },
      immediate: true
    }
  },
  methods: {
    getCityName(code) {
      const pref = this.prefData[code] || {};
      return pref;
    },
    targetOptions(target = {}) {
      const options = BASE_TARGET_OPTIONS.map(option => {
        const isExist = this.targets.some(t => t.key === option.value);
        const isMe = target.key === option.value;
        return {
          ...option,
          label: this.$t(option.label),
          description: this.$t(option.description),
          disable: isExist && !isMe
        };
      });
      return options;
    },
    remove(key) {
      const newTargets = this.targets.filter(t => t.id !== key);
      this.targets = [...newTargets];
    },
    addFilter() {
      this.add();
    },
    add(data) {
      const newTarget = data || {
        id: this.index++
      };
      this.targets.push(newTarget);
    },

    onChangedTarget(newTarget) {
      const newTargets = this.targets.map(target => {
        return target.id === newTarget.id ? newTarget : target;
      });

      this.targets = newTargets;
    },

    validate() {
      return new Promise((resolve, reject) => {
        const errors = this.targets.filter(({ key, value }) => {
          if (key !== 'location') return !value;
          return (
            !value ||
            (value &&
              value.filter_city.length + value.filter_prefecture.length + value.filter_ward.length >
                20)
          );
        });
        if (errors.length) {
          reject({ type: 'targeting', errors });
        } else {
          resolve(this.targets);
        }
      });
    }
  }
};
