//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ImageUploader from './ImageUploader.vue';
import PDFUploader from './PDFUploader.vue';
import Url from './Url.vue';
export default {
  components: {
    ImageUploader,
    PDFUploader,
    Url
  },
  props: {
    attachmentOptions: {
      type: Array,
      required: true,
      default: () => []
    },
    initialAttachment: {
      type: Object,
      required: true,
      default: () => {}
    },
    currentSize: {
      type: Number,
      required: true,
      default: 0
    },
    index: {
      type: Number,
      required: true,
      default: 0
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    isSubmited: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      attachment: {}
    };
  },
  computed: {
    canAdd() {
      if (!this.canEdit) {
        return false;
      }
      if (this.index < this.currentSize) {
        return false;
      }
      if (this.currentSize === this.attachmentOptions.length) {
        return false;
      }
      if (!this.attachment.type) {
        return false;
      }
      return true;
    }
  },
  methods: {
    add() {
      this.$emit('add');
    },

    remove() {
      this.$emit('remove', this.attachment);
    },

    changeType(value) {
      this.attachment = { ...this.attachment, type: value, url: null };
      this.$emit('changeAttachment', this.attachment);
    },
    changeUrl(url) {
      this.attachment = { ...this.attachment, url: url };
      this.$emit('changeAttachment', this.attachment);
    },
    removeUrl() {
      this.attachment = { ...this.attachment, url: null };
      this.$emit('changeAttachment', this.attachment);
    }
  },
  created() {
    this.attachment = { ...this.initialAttachment };
  }
};
