var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("q-separator", { attrs: { spaced: "" } }),
      _c(
        "q-item",
        [
          _c(
            "q-item-section",
            { staticClass: "col-4", attrs: { center: "" } },
            [
              _c("q-select", {
                attrs: {
                  options: _vm.attachmentOptions,
                  label: _vm.$t("Select"),
                  "map-options": "",
                  "emit-value": "",
                  borderless: "",
                  dense: "",
                  readonly: !_vm.canEdit,
                  "hide-dropdown-icon": !_vm.canEdit,
                  value: _vm.attachment.type
                },
                on: { input: _vm.changeType },
                scopedSlots: _vm._u([
                  {
                    key: "option",
                    fn: function(scope) {
                      return [
                        _c(
                          "q-item",
                          _vm._g(
                            _vm._b({}, "q-item", scope.itemProps, false),
                            scope.itemEvents
                          ),
                          [
                            _c(
                              "q-item-section",
                              [
                                _c("q-item-label", [
                                  _vm._v(_vm._s(_vm.$t(scope.opt.label)))
                                ]),
                                _c("q-item-label", [
                                  _vm._v(_vm._s(_vm.$t(scope.opt.description)))
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("q-separator", { attrs: { vertical: "", spaced: "" } }),
          _c(
            "q-item-section",
            { attrs: { center: "" } },
            [
              _vm.attachment.type === "url"
                ? _c("Url", {
                    attrs: {
                      initUrl: _vm.attachment.url,
                      "can-edit": _vm.canEdit,
                      "is-submited": _vm.isSubmited
                    },
                    on: { change: _vm.changeUrl }
                  })
                : _vm.attachment.type === "picture"
                ? _c("ImageUploader", {
                    attrs: {
                      initUrl: _vm.attachment.url,
                      "can-edit": _vm.canEdit,
                      "is-submited": _vm.isSubmited
                    },
                    on: { change: _vm.changeUrl, remove: _vm.removeUrl }
                  })
                : _vm.attachment.type === "pdf"
                ? _c("PDFUploader", {
                    attrs: {
                      initUrl: _vm.attachment.url,
                      "can-edit": _vm.canEdit,
                      "is-submited": _vm.isSubmited
                    },
                    on: { change: _vm.changeUrl, remove: _vm.removeUrl }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("q-item-section", { attrs: { center: "", side: "" } }, [
            _c(
              "div",
              { staticClass: "text-grey-8 q-gutter-xs" },
              [
                _vm.canAdd
                  ? _c("q-btn", {
                      attrs: {
                        flat: "",
                        color: "primary",
                        label: _vm.$t("and")
                      },
                      on: { click: _vm.add }
                    })
                  : _vm._e(),
                _vm.canEdit
                  ? _c("q-btn", {
                      staticClass: "gt-xs",
                      attrs: {
                        size: "12px",
                        flat: "",
                        dense: "",
                        round: "",
                        icon: "close"
                      },
                      on: { click: _vm.remove }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }