//
//
//
//
//

export default {
  props: {
    options: {
      type: [],
      default: []
    },
    valueSelected: {
      type: [],
      default: []
    }
  },
  computed: {
    langs() {
      return this.options.map(lang => {
        return { ...lang, disabled: lang.default };
      });
    }
  },
  data() {
    return {};
  },
  methods: {
    onChange() {}
  }
};
