var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-item",
        {
          staticClass: "py-none my-none",
          attrs: {
            "validate-status": _vm.validateStatus,
            help: _vm.errorMessage
          }
        },
        [
          _c(
            "a-upload",
            {
              staticClass: "pdf_uploader",
              attrs: {
                action: _vm.action,
                accept: ".pdf",
                "list-type": "picture",
                headers: {
                  Authorization: _vm.token
                },
                "before-upload": _vm.beforeUpload,
                name: "files",
                "file-list": _vm.fileList,
                disabled: !_vm.canEdit
              },
              on: { change: _vm.handleChange }
            },
            [
              _vm.isEmptyFileList
                ? _c(
                    "a-button",
                    { attrs: { block: "" } },
                    [
                      _c("a-icon", { attrs: { type: "upload" } }),
                      _vm._v(" " + _vm._s(_vm.$t("Upload")) + " ")
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }