//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Content, Targeting, Scheduling } from './steps';
import Preview from './preview/Index';
import { mapGetters } from 'vuex';
import i18n from '@/locales';
import { timer } from 'rxjs';
import PageHeader from '@/components/tools/PageHeaderCustom.vue';

export default {
  components: {
    Content,
    Targeting,
    Scheduling,
    Preview,
    PageHeader
  },

  data() {
    return {
      targetMsg: i18n.t('Option'),
      loading: false,
      fetching: false,
      isEdit: false,
      message: {
        localized_messages: [
          {
            lang: 'ja'
          }
        ]
      },
      done: {
        targeting: false,
        scheduling: false
      },
      errors: []
    };
  },
  computed: {
    ...mapGetters('message', ['supportedLangs', 'defaultLang', 'langByCode']),
    ...mapGetters('user', ['userInfo']),
    canSave() {
      const status = this.message.status;
      return this.isEdit ? status === 'REJECTED' || status === 'DRAFT' : true;
    },
    isRejected() {
      return this.message.status === 'REJECTED';
    },
    companyInfo() {
      return this.userInfo.company;
    }
  },
  created() {
    this.initial();
  },
  methods: {
    async initial() {
      const messageId = this.$route.params.id;
      const newMessageContent = this.$ls.get('uv-new-message-content');
      if (messageId) {
        try {
          this.fetching = true;
          this.message = await this.$s.api.message.findById(messageId, {
            filter: { include: ['code'] }
          });
          this.isEdit = true;
        } catch (error) {
          this.$notification.error({
            message: this.$tc('Error'),
            description: error.message,
            duration: 3
          });
        }
        this.fetching = false;
      } else if (newMessageContent) {
        // new message content from code page
        this.$ls.remove('uv-new-message-content');
        this.message = { ...newMessageContent };
      }
    },
    async saveMessage(data, isDraft) {
      const companyId = this.userInfo.company_id;
      try {
        this.loading = true;
        let res = null;
        if (this.isEdit) {
          res = await this.$s.api.message.update(companyId, this.message.id, data, isDraft);
        } else {
          const code_id = this.message?.code_id;
          res = await this.$s.api.message.create(
            companyId,
            { ...data, ...(code_id ? { code_id } : {}) },
            isDraft
          );
        }
        if (res.status === 'DRAFT') {
          this.$router.push({ name: 'message-detail', params: { id: res.id } });
          this.message = { ...this.message, ...res };
          this.$notification.success({ message: this.$tc('SaveDraftSuccess') });
        } else {
          this.$router.replace({ path: '/message/search', query: { status: res.status } });
          this.message = { ...res };
          this.$notification.success({ message: this.$tc('Push has been saved.') });
        }
      } catch (error) {
        // this.$message.error(this.$t("Invalid start time. Please try again."), 3);
        this.$message.error(error.message);
      }
      this.loading = false;
    },

    saveDraf() {
      this.submit('save', true);
    },

    submit(type = 'preview', isDraft = false) {
      const content = this.$refs.refContent.validate(type);
      const targeting = this.$refs.refTargeting
        ? this.$refs.refTargeting.validate()
        : Promise.resolve([]);

      const scheduling =
        this.$refs.refScheduling && type === 'save'
          ? this.$refs.refScheduling.validate()
          : Promise.resolve({});

      Promise.all([content, targeting, scheduling])
        .then(([contentData, targetData, schedulingData]) => {
          const targetOptions = targetData.reduce((acc, option) => {
            if (option.key === 'age') {
              return {
                ...acc,
                filter_max_age: option.value.max,
                filter_min_age: option.value.min
              };
            }
            if (option.key === 'location') {
              return {
                ...acc,
                ...option.value
              };
            }
            if (option.key === 'appTarget') {
              return {
                ...acc,
                app_target: option.value
              };
            }
            if (option.key === 'gender') {
              return {
                ...acc,
                filter_gender: option.value
              };
            }
          }, {});
          const payload = {
            ...contentData,
            ...targetOptions,
            ...schedulingData
          };
          delete payload.advance;
          type === 'preview'
            ? this.$refs.previewRef.showPreview(contentData)
            : this.saveMessage(payload, isDraft);
        })
        .catch(error => {
          console.log(error);
          // VAlidate in local
          if (error.type) {
            this.done[error.type] = true;
            this.errors = error.errors;
            this.$subscribeTo(timer(5000), () => {
              this.done[error.type] = false;
              this.errors = [];
            });
            // this.$message.warning(this.$t('Validate failed!'), 3);
          }
          // Error from server
          if (error.code) {
            this.$message.error(this.$t(error.message), 3);
          }
        });
    },
    showPreview() {
      this.submit('preview', false);
    }
  }
};
