//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import FileSaver from 'file-saver';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  props: {
    initUrl: {
      type: String,
      default: null
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('user', ['token']),
    isValidFile() {
      return this.fileList.some(file => file.status === 'error');
    }
  },
  data() {
    return {
      action: `${process.env.VUE_APP_API_BASE_URL}/hub/upload`,
      loading: false,
      imageUrl: this.initUrl,
      fileList: [],
      previewImage: null,
      previewVisible: false
    };
  },
  watch: {
    initUrl: {
      handler(url) {
        if (url) {
          const fileName = url.split('/').pop();
          this.fileList = [
            {
              uid: '-1',
              name: fileName,
              status: 'done',
              url: this.initUrl
            }
          ];
        }
      },
      immediate: true
    }
  },
  methods: {
    handleChange(info) {
      console.info(info.fileList);

      let fileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      //    Only to show one recent uploaded files
      fileList = fileList.slice(-1);

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          this.loading = true;
          const { status } = file.response;
          if (status === 'OK' && file.response.data && file.response.data.length) {
            file.url = file.response.data[0].link;
            this.loading = false;
          }
          if (status === 'NG') {
            file.status = 'error';
            this.loading = false;
          }
        }
        return file;
      });

      this.fileList = fileList;

      if (info.file.status === 'done') {
        this.$emit('change', this.fileList.length && this.fileList[0].url);
      }
    },
    beforeUpload(file) {
      const fileExtension = file.name.split('.').pop();
      if (!['bmp'].includes(fileExtension)) {
        this.$message.error(this.$t('Please upload the BMP file!'));
        return Promise.reject(false);
      }

      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error(this.$t('Image must smaller than 10MB!'));
        return Promise.reject(false);
      }
      return true;
    },
    handleRemove() {
      this.$emit('remove');
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    downloadLink() {
      const timestamp = new Date().getTime();
      FileSaver.saveAs(`${this.previewImage}?time=${timestamp}`, `univoice_code${timestamp}.bmp`);
    }
  }
};
